import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref as storageRef } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCZOlHZFl4GJWlXSU3raAwZJz_K0PHkMJ4",
    authDomain: "epppdm.app",
    projectId: "photodatamatching",
    storageBucket: "photodatamatching.appspot.com",
    messagingSenderId: "1095376852969",
    appId: "1:1095376852969:web:4f7e1306b93f971d059d78",
    measurementId: "G-K0EZJS51Q2"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

// Enable offline data persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      console.log('The current browser does not support all of the features required to enable persistence');
    }
  });

// Firebase Auth state change listener
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in.
    console.log('User is signed in');

    // Call getIdToken function here or wrap it in a separate function and call it when needed
    user.getIdToken(/* forceRefresh */ true)
      .then(function(idToken) {
        // Send token to your backend via HTTPS
        // ...
      })
      .catch(function(error) {
        // Handle error
      });
  } else {
    // No user is signed in.
    console.log('No user is signed in');
  }
});

export { app, analytics, storage, storageRef, auth, onAuthStateChanged, db };
