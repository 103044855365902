import React, { useEffect, useState } from 'react';
import './App.css';
import Select from 'react-select';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

// Firebase
import { 
  getAuth, 
  signInWithPopup, 
  signOut, 
  GoogleAuthProvider 
} from 'firebase/auth';

import { 
  doc, 
  setDoc, 
  getDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  deleteDoc,
  } from 'firebase/firestore';

import { 
  ref, 
  uploadBytes,
  } from 'firebase/storage';

// Not currently used:
// import { getDatabase } from 'firebase/database';

import { 
  storage, 
  onAuthStateChanged, 
  db 
} from './firebase';

const auth = getAuth();




const App = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [photoTaken, setPhotoTaken] = useState(false);
  const [photoBlob, setPhotoBlob] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [setPhotoFilename] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [capturedImage, setCapturedImage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [editedSubjectData, setEditedSubjectData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const [selectedDataSetIndex, setSelectedDataSetIndex] = useState(null);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showNotificationButton, setShowNotificationButton] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState(0);
  const [uid, setUid] = useState(null);
  const handleDataSetChange = (selectedOption) => {
    setSelectedDataSetIndex(selectedOption.value); // update the selected data set index
  };
  const dataSetOptions = dataSets.map((dataSet, index) => ({
    value: index, // we're using the index as the value for simplicity
    label: dataSet.name // assuming each dataSet has a name property
  }));
  const [isSignedIn, setIsSignedIn] = useState(false); // Add this state to track sign-in status
  const [selectedProjectForExport, setSelectedProjectForExport] = useState(null);
  const getUniqueProjects = () => {
    const projectNames = dataSets.flatMap((dataSet) => dataSet.projects.map((project) => project.name));
    return [...new Set(projectNames)];
  };

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid); // If a user is signed in, set the uid state
        setIsSignedIn(true);
      } else {
        setUid(null); // If a user is signed out, reset the uid state
        setIsSignedIn(false);
      }
    });
  
    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      const docSnap = await getDoc(doc(db, "dataSets", "dataSetsId"));
      if (docSnap.exists()) {
        setDataSets(docSnap.data().dataSetArray);
      }
    };
    fetchData();
  }, []);
  

useEffect(() => {
    if (selectedDataSetIndex !== null) {
      setSubjects(dataSets[selectedDataSetIndex].subjects);
      setProjects(dataSets[selectedDataSetIndex].projects);
      setSelectedProject({
        value: dataSets[selectedDataSetIndex].projects[0].id,
        label: dataSets[selectedDataSetIndex].projects[0].name,
      });
      // Update newSubjectData with the selected project.
      setNewSubjectData(prev => ({
        ...prev,
        project: dataSets[selectedDataSetIndex].projects[0],
      }));
      setSelectedSubject(null);
    }
  }, [selectedDataSetIndex, dataSets]);


  const handleCSVUpload = async (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const data = results.data.map((row) => ({
          ...row,
          id: uuidv4(),
          hasPhoto: false,
        }));
  
        setSubjects(data);

        const uniqueProjects = Array.from(
          new Set(data.map((row) => row.projectname))
        ).map((projectName) => ({
          id: uuidv4(),
          name: projectName,
        }));
        
        const newData = {
          id: uuidv4(),
          name: file.name,
          projects: uniqueProjects,
          subjects: data,
          userId: uid,
          timestamp: Date.now(),
        };
      
        // Now set newData directly into Firestore
        const newDataSet = [...dataSets, newData];
        await setDoc(doc(db, "dataSets", newData.id), newData); // Change this line
      
        setDataSets(newDataSet);
      

        setSelectedProject({ value: uniqueProjects[0].id, label: uniqueProjects[0].name });
      }
    });
  };
  
  
      
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log('Signed in with Google:', user);
        setUid(user.uid);  // <-- Add this line
        console.log('User ID:', user.uid);
        setIsSignedIn(true); // Set sign-in status to true
      })
      .catch((error) => {
        console.error('Google sign-in error:', error);
      });
  };
  
  const signOutUser = () => {
    signOut(auth)
    .then(() => {
      console.log('Signed out');
      setIsSignedIn(false); // Set sign-in status to false
    })
    .catch((error) => {
      console.error('Sign-out error:', error);
    });
  };
// eslint-disable-next-line no-unused-vars
  const currentDataSet = dataSets[selectedDataSetIndex] || { projects: [], subjects: [] };

   

 const [newSubjectData, setNewSubjectData] = useState({ firstname: '', lastname: '', subjectId: '', grade: '', project: null });

  const handleRemoveDataSet = async () => {
    if (window.confirm("Are you sure you want to remove this data set? This action cannot be undone.")) {
      const newDataSet = [...dataSets]; // Create a copy of the current datasets
      const dataSetToRemove = newDataSet.splice(selectedDataSetIndex, 1)[0];
      setDataSets(newDataSet); // Update the state
      await deleteDoc(doc(db, "dataSets", dataSetToRemove.id)); // Delete the dataset in Firestore
      setSelectedDataSetIndex(null); // Reset the selected dataset index
    }
  };
// eslint-disable-next-line no-unused-vars
  const handleUpdateDataSet = async (updatedDataSet) => {
    const updatedDataSets = dataSets.map((dataSet) => {
      if (dataSet.id === updatedDataSet.id) {
        return updatedDataSet;
      }
      return dataSet;
    });

    setDataSets(updatedDataSets);
    await setDoc(doc(db, "dataSets", updatedDataSet.id), updatedDataSet); // Update the dataset in Firestore
  };




  const handleNewSubjectDataChange = (field, value) => {
    setNewSubjectData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  
  // Then inside handleAddNewSubject method, include the project data when creating new subject.
  const handleAddNewSubject = async () => {
    const newSubject = {
      ...newSubjectData,
      id: uuidv4(),
      hasPhoto: false,
      studentid: newSubjectData.subjectId, 
      grade: newSubjectData.grade,
      projectname: newSubjectData.project.name, // Add the project name to the new subject
      project: newSubjectData.project.id // Add the project id to the new subject
    };
    const updatedSubjects = [...subjects, newSubject];
    const updatedDataSets = dataSets.map((dataSet, index) => {
      if (index === selectedDataSetIndex) {
        return {
          ...dataSet,
          subjects: updatedSubjects,
        };
      }
      return dataSet;
    });
    setDataSets(updatedDataSets);
    await Promise.all(updatedDataSets.map((dataSet) => setDoc(doc(db, "dataSets", dataSet.id), dataSet)));
  
  
    // Find the newly added subject from the updated data set and set it as the selected subject
    const updatedSubject = updatedDataSets[selectedDataSetIndex].subjects.find((subject) => subject.id === newSubject.id);
    setSelectedSubject(updatedSubject);
  
    // Clear the form
    setNewSubjectData({ firstname: '', lastname: '', subjectId: '', grade: '' });
  };

  
  const handleCapture = (e) => {
    const file = e.target.files[0];
  
    if (!file) {
      return; // Return early if no file is selected
    }
  
    const url = URL.createObjectURL(file);
  
    if (!selectedSubject) {
      alert("Please select a subject before capturing photo.");
      return;
    }
  
    // Increment the sequence number for the next capture
    setSequenceNumber(sequenceNumber + 1);
    setCapturedImage({ file, url });
    setPhotoTaken(true);
    setPhotoBlob(file);
    setPhotoURL(url);
  };
  
  const handleRetake = () => {
    setCapturedImage(null);
    setPhotoTaken(false);
    setPhotoBlob(null);
    setPhotoURL(null);
    setPhotoFilename(null);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const date = new Date();
      const year = date.getFullYear().toString().slice(-2); // Get last two digits of year
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JS
      const day = date.getDate().toString().padStart(2, '0');
      const dateStamp = `${year}${month}${day}`;
  
      const paddedSequenceNumber = String(sequenceNumber).padStart(4, '0');
      const newFilename = `${selectedSubject.lastname}_${selectedSubject.firstname}_${dateStamp}_${paddedSequenceNumber}.jpg`;
  
      const photoRef = ref(storage, `images/${newFilename}`);
      await uploadBytes(photoRef, photoBlob);
  
      // Update the relevant subject's data
      const updatedSubjects = subjects.map((subject) => {
        if (subject.id === selectedSubject.id) {
          return {
            ...subject,
            hasPhoto: true,
            filename: newFilename,  // Updated the filename here
          };
        }
        return subject;
      });
  
      // Update the dataSets state as well
      const updatedDataSets = dataSets.map((dataSet, index) => {
        if (index === selectedDataSetIndex) {
          return {
            ...dataSet,
            subjects: updatedSubjects,
          };
        }
        return dataSet;
      });
  
      // Update the state
      setDataSets(updatedDataSets);
  
      // Find the current dataset by its index
      const currentDataSet = updatedDataSets[selectedDataSetIndex];
  
      // Create or update the subject's document in Firestore
      await setDoc(doc(db, "subjects", selectedSubject.id), {filename: newFilename, hasPhoto: true});
      
      // Update the current dataset document in Firestore
      await setDoc(doc(db, "dataSets", currentDataSet.id), currentDataSet);
  
      setIsSaving(false);
      setPhotoTaken(false);
      setPhotoBlob(null);
      setPhotoURL(null);
  
      // Increment the sequence number for the next capture
      setSequenceNumber(sequenceNumber + 1);
  
      // Show the notification button
      setShowNotificationButton(true);
    } catch (error) {
      console.error("Error uploading photo: ", error);
      setIsSaving(false);
    }
  };
  
  
  
  
  
  
  // Use another useEffect to manage the states after a successful save
  useEffect(() => {
    let timeoutId;
    if (uploadSuccess) {
      // Delay resetting states to keep button visible
      timeoutId = setTimeout(() => {
        setShowSaveConfirmation(false);
        setUploadSuccess(false); // Reset uploadSuccess after 3 seconds
  
        // Reset these states after 3 seconds as well
        setCapturedImage(null);
        setPhotoTaken(false);
        setPhotoBlob(null);
        setPhotoURL(null);
        setPhotoFilename(null);
        setSelectedSubject(null);
        setEditedSubjectData(null);
      }, 3000);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [uploadSuccess]);
  
  useEffect(() => {
    let timeoutId;
    if (showNotificationButton) {
      timeoutId = setTimeout(() => {
        setShowNotificationButton(false);
      }, 3000);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showNotificationButton]);
  

  
useEffect(() => {
  if (uid) {
    const fetchUserCSVs = async () => {
      const csvQuery = query(
        collection(db, "dataSets"),
        where("userId", "==", uid),
        orderBy("timestamp", "desc")
      );

      const csvSnapshots = await getDocs(csvQuery);
      
      const csvs = csvSnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setDataSets(csvs);
    };

    fetchUserCSVs();
  }
}, [uid]);


  
  

  const handleProjectChange = (selected) => {
    setSelectedProject(selected);
    setSelectedSubject(null);
  };

  const handleSubjectChange = (selected) => {
    if(selectedDataSetIndex !== null && dataSets[selectedDataSetIndex]) {
      const updatedSubject = dataSets[selectedDataSetIndex].subjects.find((subject) => subject.id === selected.value);
      setSelectedSubject(updatedSubject);
    }
  };
  

const handleExport = async () => {
  if (!selectedProjectForExport) {
    alert("Please select a project to export.");
    return;
  }

  const dataToExport = [];

  dataSets.forEach((dataSet) => {
    if(Array.isArray(dataSet.subjects)) {
      dataSet.subjects.forEach((subject) => {
        if (subject.projectname === selectedProjectForExport.value) {
          const { projectname, id, hasPhoto, ...exportData } = subject;
          dataToExport.push({
            ...exportData,
            filename: subject.filename || '', // Add the filename column
            photographed: subject.hasPhoto ? 'Yes' : 'No', // Add the photographed column
          });
        }
      });
    }
  });

  const csvContent = Papa.unparse(dataToExport);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${selectedProjectForExport.value}_export.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

  
  
  
  const isFormValid = () => {
    return (
      newSubjectData.firstname &&
      newSubjectData.lastname &&
      newSubjectData.subjectId &&
      newSubjectData.grade
    );
  };

  const [showPhotoList, setShowPhotoList] = useState(false);

  const togglePhotoList = () => {
    setShowPhotoList(!showPhotoList);
  };




  return (
    <div className="App">
      <h1>PhotoMatcher</h1>
      <button 
        className={isSignedIn ? 'btn-signed-in' : 'btn-sign-in'} 
        onClick={isSignedIn ? signOutUser : signInWithGoogle}
      >
        {isSignedIn ? 'Signed in' : 'Sign in with Google'}
      </button>
      <button onClick={signOutUser}>Sign out</button>
    
      <input type="file" accept=".csv" onChange={handleCSVUpload} />
       <Select
       className="select"
        placeholder="data from csv upload"
  options={dataSetOptions}
  onChange={handleDataSetChange} // function to handle change
  isSearchable={true} // make the dropdown searchable
  
/>
      <Select
        className="select"
        placeholder="Select Data Set"
        value={
          selectedDataSetIndex !== null && {
            value: selectedDataSetIndex,
            label: dataSets[selectedDataSetIndex].name,
          }
        }
        onChange={handleDataSetChange}
        options={dataSets.map((dataSet, index) => ({
          value: index,
          label: dataSet.name,
        }))}
      />
       
      <div className="select-container">
        <div className="select-wrapper">
          <Select
            className="select"
            placeholder="Select Project"
            value={selectedProject}
            onChange={handleProjectChange}
            options={projects.map((project) => ({
              value: project.id,
              label: project.name,
            }))}
          />
      <Select
  className="select"
  placeholder="Select Subject"
  value={
    selectedSubject && {
      value: selectedSubject.id,
      label: `${selectedSubject.firstname} ${selectedSubject.lastname} (${selectedSubject.subjectId}) - Grade: ${selectedSubject.grade}`,
    }
  }
  onChange={handleSubjectChange}
  options={subjects.map((subject) => ({
    value: subject.id,
    label: `${subject.firstname} ${subject.lastname} (${subject.subjectId}) - Grade: ${subject.grade} ${subject.hasPhoto ? '(Photo)' : ''}`,
  }))}
/>

          <button onClick={togglePhotoList}>Show Photo List</button>
          {showPhotoList && (
          <Select
            className="select"
            placeholder="Search Subjects with Photos"
            options={subjects
              .filter((subject) => subject.hasPhoto)
              .map((subject) => ({
                value: subject.id,
                label: `${subject.firstname} ${subject.lastname} (${subject.subjectId}) - Grade: ${subject.grade}`,
              }))}
            isSearchable
          />
             )}
      
          </div>
      </div>
      {selectedSubject && !uploadSuccess && (
  <div className="photo-section">
    {!photoTaken && <input type="file" accept="image/*" onChange={handleCapture} />}

    {(photoTaken || uploadSuccess) && (
      <>
        <img src={photoURL} alt="Captured" className="captured-image" />

        <button onClick={handleRetake}>Retake Photo</button>
        <button onClick={handleSave} disabled={isSaving} className={showSaveConfirmation ? 'button-success' : ''}>
          {isSaving ? 'Uploading...' : (showSaveConfirmation ? 'Upload Successful' : 'Save Photo')}
        </button>

        {showSaveConfirmation && <div className="notification">Photo saved!</div>}
      </>
    )}
  </div>
)}

   
      <div className="new-subject-form">
        <input
          type="text"
          placeholder="First Name"
          value={newSubjectData.firstname}
          onChange={(e) => handleNewSubjectDataChange('firstname', e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={newSubjectData.lastname}
          onChange={(e) => handleNewSubjectDataChange('lastname', e.target.value)}
        />
        <input
          type="text"
          placeholder="Subject ID"
          value={newSubjectData.subjectId}
          onChange={(e) => handleNewSubjectDataChange('subjectId', e.target.value)}
        />
        <input
          type="text"
          placeholder="Grade"
          value={newSubjectData.grade}
          onChange={(e) => handleNewSubjectDataChange('grade', e.target.value)}
        />
   <input 
    type="text"
    placeholder="Project"
    readOnly
     value={newSubjectData.project ? newSubjectData.project.name : ''}
  />
      
      </div>
   
      <button onClick={handleAddNewSubject} disabled={!isFormValid()}>
        Add New Subject
      </button>
     
      <button onClick={handleExport}>Export CSV</button>
      <button onClick={handleRemoveDataSet} disabled={selectedDataSetIndex === null}>Remove Selected Data Set</button>
      {
  showNotificationButton && (
    <button className="notification-button">Photo Saved Successfully!</button>
   

  )
}
<Select
  options={getUniqueProjects().map((project) => ({ value: project, label: project }))}
  value={selectedProjectForExport}
  onChange={(selectedOption) => setSelectedProjectForExport(selectedOption)}
  placeholder="Select a project to export"
/>
    </div>
  );

}

export default App;